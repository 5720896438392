import React from "react"
import { Link } from "gatsby"
import { Highlight } from "react-instantsearch-dom"
//styled icons
import { CalendarAlt } from "styled-icons/fa-regular/CalendarAlt"
import { Tags } from "styled-icons/fa-solid/Tags"

const PostSearchPreview = ({ hit }) => {
  return (
    <>
      <header>
        <h3>
          <Link to={hit.fields.slug}>
            <Highlight hit={hit} attribute="frontmatter.title" tagName="mark" />
          </Link>
        </h3>
      </header>
      <CalendarAlt size="1em" /> &nbsp;
      <small>{hit.frontmatter.date}</small>
      &emsp;
      <Tags size="1em" />
      &nbsp;
      {hit.frontmatter.tags.map((tag, index) => (
        <React.Fragment key={tag}>
          <small>
            {index > 0 && `, `}
            {tag}
          </small>
        </React.Fragment>
      ))}
      <p>
        <Highlight hit={hit} attribute="excerpt" tagName="mark" />
      </p>
    </>
  )
}

export default PostSearchPreview
