import React from "react"
import useDarkMode from "use-dark-mode"
import styled, { css } from "styled-components"
import { Moon, Sun } from "styled-icons/boxicons-solid"
import { colors, themeMedia } from "../utils/theme"
import { rhythm } from "../utils/typography"
/*
Styled components for the dark mode toggle
 */
const ToggleControl = styled.div`
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
  @media (max-width: ${themeMedia.maxXsmall}) {
    margin-bottom: ${rhythm(1 / 2)};
  }
`
const DarkModeSwicth = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
`
const ButtonStyled = styled.button`
  background: none;
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  outline: none;
  &:hover {
    transform: scale(1.06);
  }
`
const SwitchControl = styled.div`
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
`

const DarkModeCheck = styled.input`
  width: 40px;
  height: 15px;
  background-color: ${({ theme }) => theme.toggleTrackColor};
  transition: ${({ theme }) => theme.baseThemeTransition};
  position: relative;
  border-radius: 5px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;
  box-shadow: ${({ theme }) => theme.switchTrackInnerShadow};
`
const SwitchIndicator = styled.label`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: transform 0.4s cubic-bezier(0.34, 1.61, 0.7, 1),
    ${({ theme }) => theme.baseThemeTransition};
  -moz-transition: transform 0.4s cubic-bezier(0.34, 1.61, 0.7, 1);
  cursor: pointer;
  position: absolute;
  transform: translateX(${({ checked }) => (!checked ? `-7px` : `26px`)});
  opacity: 1;
  background-color: ${({ theme }) => theme.toggleSwitchColor};
  box-shadow: ${({ theme }) => theme.switchToggleBoxShadow};

  &:hover {
    //transform: scale(1.1);
  }
`
const SunStyled = styled(Sun)`
  display: flex;
  align-self: center;
  height: 24px;
  width: 24px;
  fill: ${({ theme, checked }) =>
    checked ? theme.textColor : colors.darkSunglow};
  transition: fill 0.5s ease;
`
const MoonStyled = styled(Moon)`
  display: flex;
  align-self: center;
  height: 24px;
  width: 24px;
  fill: ${({ theme, checked }) => (checked ? colors.indigo : theme.textColor)};
  transition: fill 0.5s ease;
`

const DarkModeToggle = () => {
  const darkMode = useDarkMode(false)
  return (
    <ToggleControl>
      <DarkModeSwicth>
        <ButtonStyled onClick={darkMode.disable} aria-label="light mode button">
          <SunStyled checked={darkMode.value} />
        </ButtonStyled>
        <SwitchControl>
          <DarkModeCheck
            type="checkbox"
            checked={darkMode.value}
            onChange={darkMode.toggle}
            id="dark-mode-toggle"
            label="dark mode toggle"
          />
          <label
            htmlFor="dark-mode-toggle"
            css={css`
              clip: rect(1px, 1px, 1px, 1px);
              clip-path: inset(50%);
              height: 1px;
              width: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
            `}
          >
            Mode jour/nuit
          </label>
          <SwitchIndicator
            htmlFor="dark-mode-toggle"
            checked={darkMode.value}
          />
        </SwitchControl>
        <ButtonStyled onClick={darkMode.enable} aria-label="dark mode button">
          <MoonStyled checked={darkMode.value} />
        </ButtonStyled>
      </DarkModeSwicth>
    </ToggleControl>
  )
}

export default DarkModeToggle
