import { throttle } from "lodash"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { UpArrowSquare } from "styled-icons/boxicons-solid"

const UpArrow = styled(UpArrowSquare)`
  color: #0083ea;
  position: fixed;
  width: 4em;
  height: 4em;
  bottom: 2em;
  right: 2em;
  transition: all 0.5s ease-in-out;
  visibility: ${props => (props.displayButton ? `visible` : `hidden`)};
  opacity: ${props => (props.displayButton ? `0.75` : `0`)};

  :hover {
    transform: scale(1.3);
  }
`

const ScrollToTop = ({ displayButtonBelow }) => {
  const [displayButton, setDisplayButton] = useState(
    displayButtonBelow ? false : true
  )

  const scroll = () => window.scrollTo({ top: 0, behavior: "smooth" })

  const handleScroll = throttle(() => {
    if (!displayButtonBelow) return
    if (window.scrollY > displayButtonBelow) {
      if (!displayButton) setDisplayButton(true)
    } else if (displayButton) setDisplayButton(false)
  }, 400)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleClick = () => {
    scroll()
  }

  return <UpArrow onClick={handleClick} displayButton={displayButton} />
}

export default ScrollToTop
