import React from "react"
import { Link } from "gatsby"
import useDarkMode from "use-dark-mode"

import GatsbyLogo from "../../content/assets/Gatsby_Logo.svg"
import GatsbyLogoWhite from "../../content/assets/Gatsby_Logo_White.svg"

//utilities
import { rhythm } from "../utils/typography"
import styled from "styled-components"
import themeColors, { themeMedia } from "../utils/theme"

const FooterStyled = styled.footer`
  display: flex;
  color: ${themeColors.text};
  padding: 0 ${rhythm(1 / 2)};
  margin-top: ${rhythm(1)};
  margin-bottom: ${rhythm(1 / 2)};
  justify-content: space-between;

  & p {
    margin-bottom: 0;
    @media (max-width: ${themeMedia.maxXsmall}) {
      margin: 0 auto;
    }
  }

  & p > a {
    text-decoration: none;
    box-shadow: none;
  }

  @media (max-width: ${themeMedia.maxXsmall}) {
    flex-direction: column;
  }
`
const GatsbyLogoStyledWhite = styled(GatsbyLogoWhite)`
  height: 1.5em;
  transform: translateY(0.33em);
  width: auto;
  color: ${({ theme }) => theme.textColor};

  & :hover {
    transform: none;
  }
`
const GatsbyLogoStyled = styled(GatsbyLogo)`
  height: 1.5em;
  transform: translateY(0.33em);
  width: auto;
  color: ${({ theme }) => theme.textColor};

  & :hover {
    transform: none;
  }
`
const LinkStyled = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  @media (max-width: ${themeMedia.maxXsmall}) {
    margin: 0 auto;
  }
`

const Footer = () => {
  const darkMode = useDarkMode().value
  return (
    <FooterStyled>
      <p>
        © {new Date().getFullYear()} - Mathias OVIEVE - codé avec 💜 et
        {` `}
        <a href="https://www.gatsbyjs.org">
          {(darkMode && <GatsbyLogoStyledWhite />) || <GatsbyLogoStyled />}
          {}
        </a>
      </p>
      <LinkStyled to={`/mentions-legales`}> Mentions légales </LinkStyled>
    </FooterStyled>
  )
}

export default Footer
