export const colors = {
  white: "#ffffff",
  athensGray: "#f1f2f6", // card background
  bunker: "#10171d", //dark mode background
  mirage: "#202e40", //darkmode card background
  charade: "#2f3542", //light text color
  alto: "#dfdfdf", //dark text color
  bluelinks: "#092e67",

  //light theme gradient
  malibu: "#74b9ff",
  azureRadiance: "#0984e3",
  //dark theme gradient
  blueRibbon: "#0053eb",
  regalBlue: "#043d68",
  //fallback colors for gradient
  indigo: "#457fca",
  biscay: "#1a375c",

  //toggle track/switch color
  metal: "#555555",
  aluminium: "#a1a1a1",

  //algoliasearch colors
  submarine: "#B2BEC3",
  nevada: "#636E72",
  shamrockish: "#2ecc71",
  jungleGreen: "#27ae60",
  palmSplash: "#17543d",

  cinnabar: "#e74c3c",
  tallPoppy: "#c0392b",

  /*
  Colors used for tags
   */
  sunglow: "#fed330", //light js tag
  darkSunglow: "#f7b731", //dark js tag
  neonCarrot: "#FD9644",
  crusta: "#FA8231",
  turquoise: "#2BCBBA",
  java: "#0FB9B1",
  pictonBlue: "#45AAF2",
  curiousBlue: "#2D98DA",
  royalBlue: "#4B7BEC",
  mariner: "#4371ff",
  mediumPurple: "#b164fb",
  amethyst: "#a15bef",
  paradiseGreen: "#b8e994",
  auroraGreen: "#78e08f",
  shamrock: "#26DE81",
  mountainMeadow: "#20BF6B",
  carnation: "#FC5C65",
  amaranth: "#eb3b5a",
}
export const boxShadow = {
  baseBoxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  hoveredBoxShadow: "0px 8px 8px rgba(0, 0, 0, 0.20)",
  switchToggleBoxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
  switchTrackInnerShadow: "inset 0 2px 2px rgba(0, 0, 0, 0.25)",
}
export const transitions = {
  baseThemeTransition: "background-color 0.3s ease, color 0.3s ease",
}

export const lightTheme = {
  background: colors.white,
  textColor: colors.charade,

  cardBg: colors.athensGray,
  navbarBackground: colors.athensGray,

  headerGradientFrom: colors.malibu,
  headerGradientTo: colors.azureRadiance,
  headerGradientFallback: colors.indigo,

  links: colors.bluelinks,
  hoveredLinks: colors.regalBlue,

  submitButtonBg: colors.shamrock,
  clearButtonBg: colors.cinnabar,

  contactSuccessColor: colors.shamrockish,

  toggleTrackColor: colors.aluminium,
  toggleSwitchColor: colors.metal,

  //algolia Search Styling
  algoliaHitsWrapperBackground: colors.white,
  algoliaHitsTitleBackground: colors.submarine,
  algoliaFocusBackground: colors.alto,

  /*
  Light Mode tags colors
   */
  javascript: colors.sunglow,
  "es-6": colors.neonCarrot,
  api: colors.turquoise,
  gatsby: colors.mediumPurple,
  react: colors.royalBlue,
  style: colors.pictonBlue,
  general: colors.paradiseGreen,
  entrepreneuriat: colors.turquoise,
  astuce: colors.shamrock,
  cms: colors.crusta,
  pwa: colors.auroraGreen,
}

export const darkTheme = {
  background: colors.bunker,
  textColor: colors.alto,

  cardBg: colors.mirage,
  navbarBackground: colors.mirage,
  //header gradient
  headerGradientFrom: colors.blueRibbon,
  headerGradientTo: colors.mariner,
  headerGradientFallback: colors.biscay,

  links: colors.malibu,
  hoveredLinks: colors.pictonBlue,

  submitButtonBg: colors.jungleGreen,
  clearButtonBg: colors.tallPoppy,

  contactSuccessColor: colors.palmSplash,

  toggleTrackColor: colors.metal,
  toggleSwitchColor: colors.aluminium,

  algoliaHitsWrapperBackground: colors.charade,
  algoliaHitsTitleBackground: colors.bunker,
  algoliaFocusBackground: colors.bunker,
  /*
  Dark Mode tags colors
   */
  javascript: colors.darkSunglow,
  "es-6": colors.crusta,
  api: colors.java,
  gatsby: colors.amethyst,
  react: colors.mariner,
  style: colors.curiousBlue,
  general: colors.auroraGreen,
  entrepreneuriat: colors.java,
  astuce: colors.mountainMeadow,
  cms: colors.crusta,
  pwa: colors.mountainMeadow,
}

export const themeColors = {
  background: "#f1f2f6",
  text: "#2f3542",
}
export const themeRules = {
  baseBoxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
}
export const themeMedia = {
  maxXsmall: "575.98px",
  maxSmall: "767.98px",
  maxMedium: "991.98px",
  maxLarge: "1199.98px",
}

export const theme = { ...colors, ...boxShadow, ...transitions }

export default isDarkMode =>
  isDarkMode ? { ...theme, ...darkTheme } : { ...theme, ...lightTheme }
