import React from "react"
import { CodeCurly } from "styled-icons/boxicons-regular"
import { Code, CodeBranch } from "styled-icons/fa-solid"
import { Javascript } from "styled-icons/boxicons-logos"
import {
  Css3Alt,
  Github,
  Html5,
  NodeJs,
  ReactLogo,
} from "styled-icons/fa-brands"
import GatsbyLogo from "../../content/assets/Gatsby_Monogram_White.svg"
import styled, { css, keyframes } from "styled-components"

//Animation display
const animate = keyframes`
    0% {
      transform: translateY(0) rotate(0deg);
      opacity: 1;
      border-radius: 0;
    }

    100% {
      transform: translateY(-1000px) rotate(720deg);
      opacity: 0;
      border-radius: 50%;
    }
`
const AnimationArea = styled.div`
  background: ${({ theme }) =>
    theme.headerGradientFallback}; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    ${({ theme }) => theme.headerGradientFrom},
    ${({ theme }) => theme.headerGradientTo}
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.headerGradientFrom},
    ${({ theme }) => theme.headerGradientTo}
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  transition: background 1s ease;
  width: 100%;
  margin-bottom: 0;
  ${props => (props.isRootOrPagination ? big : small)};
`
const big = css`
  height: 10em;
`
const small = css`
  height: 5em;
`
const IconsContainer = styled.ul`
  //position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
`
const GatsbyLogoStyled = styled(GatsbyLogo)`
  opacity: 0.4;
`
const IconToAnimate = styled.li`
  position: relative;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  color: rgba(255, 255, 255, 0.2);
  //background: rgba(255, 255, 255, 0.6);
  animation: ${animate} 25s linear infinite;
  bottom: -150px;
  & :nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }
  & :nth-child(2) {
    left: 10%;
    width: 90px;
    height: 90px;
    animation-delay: 2s;
    animation-duration: 12s;
  }
  & :nth-child(3) {
    left: 70%;
    width: 80px;
    height: 80px;
    animation-delay: 4s;
  }
  & :nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }
  & :nth-child(5) {
    left: 65%;
    width: 50px;
    height: 50px;
    animation-delay: 0s;
  }

  & :nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  & :nth-child(7) {
    left: 35%;
    width: 100px;
    height: 100px;
    animation-delay: 7s;
  }

  & :nth-child(8) {
    left: 50%;
    width: 70px;
    height: 70px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  & :nth-child(9) {
    left: 20%;
    width: 60px;
    height: 60px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  & :nth-child(10) {
    left: 85%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
`

const AnimatedBackgroundHeader = props => {
  return (
    <AnimationArea isRootOrPagination={props.isRootOrPagination}>
      <IconsContainer>
        <IconToAnimate>
          <CodeCurly />
        </IconToAnimate>
        <IconToAnimate>
          <Code />
        </IconToAnimate>
        <IconToAnimate>
          <CodeBranch />
        </IconToAnimate>
        <IconToAnimate>
          <Javascript />
        </IconToAnimate>
        <IconToAnimate>
          <ReactLogo />
        </IconToAnimate>
        <IconToAnimate>
          <NodeJs />
        </IconToAnimate>
        <IconToAnimate>
          <GatsbyLogoStyled />
        </IconToAnimate>
        <IconToAnimate>
          <Github />
        </IconToAnimate>
        <IconToAnimate>
          <Css3Alt />
        </IconToAnimate>
        <IconToAnimate>
          <Html5 />
        </IconToAnimate>
      </IconsContainer>
    </AnimationArea>
  )
}

export default AnimatedBackgroundHeader
