import React from "react"
import Search from "./Search"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import styled from "styled-components"
import { themeMedia } from "../utils/theme"
import DarkModeToggle from "./DarkModeToggle"

const StyledNav = styled.nav`
  height: ${rhythm(2)};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  margin-bottom: ${rhythm(2)};
  background-color: ${({ theme }) => theme.navbarBackground};
  box-shadow: ${({ theme }) => theme.baseBoxShadow};
  transition: ${({ theme }) => theme.baseThemeTransition};
  border-radius: 0 0 2em 2em;
  @media (max-width: ${themeMedia.maxXsmall}) {
    flex-direction: column;
    padding-bottom: ${rhythm(1 / 2)};
    height: max-content;
  }
`
const StyledLink = styled(Link)`
  @media (max-width: ${themeMedia.maxXsmall}) {
    margin-bottom: ${rhythm(1 / 2)};
    &:first-child {
      margin-top: ${rhythm(1 / 2)};
    }
  }
`

const Navbar = () => {
  return (
    <StyledNav className="nav">
      <StyledLink to={`/`}>Accueil</StyledLink>
      <StyledLink to={`/tags`}>Les Catégories</StyledLink>
      <StyledLink to={`/me-contacter`}>Me contacter</StyledLink>
      <DarkModeToggle />
      <Search collapse />
    </StyledNav>
  )
}

export default Navbar
