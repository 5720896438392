import React from "react"
import { Link } from "gatsby"
//components
import Logo from "../../content/assets/logoWithText.svg"
import Navbar from "./Navbar"
import AnimatedBackgroundHeader from "./AnimatedBackgroundHeader"
//utilities
import { rhythm } from "../utils/typography"
import styled from "styled-components"

/*
Styled components
 */

const StyledLink = styled(Link)`
  box-shadow: none;
  text-decoration: none;
  color: inherit;
`
const StyledLogoBig = styled(Logo)`
  padding: ${rhythm(1 / 2)};
  height: 9em;
  max-width: 90vw;
`
const StyledLogoSmall = styled(Logo)`
  padding: ${rhythm(1 / 4)};
  height: 80px;
  width: auto;
`

const LogoDisplayOnTop = styled.div`
  //height: 10%;
  position: absolute;
`

const Header = props => {
  const { location } = props
  const isRootPath = location.pathname === `${__PATH_PREFIX__}/`
  const pageNumber = location.pathname
    .split("/")
    .filter(Boolean)
    .pop()
  const isPagination = pageNumber && Boolean(pageNumber.match(/^[0-9]+$/))
  const isRootOrPagination = isRootPath || isPagination
  let header

  if (isRootPath || isPagination) {
    header = (
      <LogoDisplayOnTop>
        <StyledLink to={`/`}>
          <StyledLogoBig />
        </StyledLink>
      </LogoDisplayOnTop>
    )
  } else {
    header = (
      <LogoDisplayOnTop>
        <StyledLink to={`/`}>
          <StyledLogoSmall />
        </StyledLink>
      </LogoDisplayOnTop>
    )
  }
  return (
    <header>
      {header}
      <AnimatedBackgroundHeader isRootOrPagination={isRootOrPagination} />
      <Navbar />
    </header>
  )
}

export default Header
