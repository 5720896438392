import React, { useState, useEffect, createRef } from "react"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  Hits,
  connectStateResults,
} from "react-instantsearch-dom"
import SearchInput from "./SearchInput"
import PostSearchPreview from "./PostSearchPreview"
//utilities
import { Root, HitsWrapper, PoweredByStyled } from "./styles/SearchStyle"

//wrapper to show error > nothing if no query > results if any > message if no result
const Content = connectStateResults(
  ({ searchState: state, searchResults: res, error, children }) =>
    error ? (
      <div> Une erreur interne est survenue</div>
    ) : state && state.query ? (
      res && res.nbHits > 0 ? (
        children
      ) : (
        <div>
          {" "}
          Aucun résultat n'a été trouvé pour <strong>{state.query}</strong>
        </div>
      )
    ) : (
      <div>Zone de recherche tristement vide :'(</div>
    )
)
//handle click to show and collapse search box
const useClickOutside = (ref, handler, events) => {
  if (!events) events = ["mousedown", "touchstart"]
  const detectClickOutside = event =>
    !ref.current.contains(event.target) && handler()
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside)
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  })
}

const searchClient = algoliasearch(
  "ETQ96OPW34",
  "2804393c2ac19ad6fecad4e0607799fb"
)

const Search = ({ collapse, hitsAsGrid }) => {
  const ref = createRef()
  const [query, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)

  useClickOutside(ref, () => setFocus(false))
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="geeklecode"
      onSearchStateChange={({ query }) => setQuery(query)}
      root={{ Root, props: { ref } }}
    >
      <SearchInput onFocus={() => setFocus(true)} {...{ collapse, focus }} />
      <HitsWrapper show={query.length > 0 && focus} asGrid={hitsAsGrid}>
        <Content>
          {" "}
          <Hits hitComponent={PostSearchPreview} />
        </Content>
        <PoweredByStyled />
      </HitsWrapper>
    </InstantSearch>
  )
}

export default Search
