import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
 body {
    /* below rules enable dark mode */
    background: ${props => props.theme.background};
    color: ${props => props.theme.textColor};
    transition: ${({ theme }) => theme.baseThemeTransition};
    a {
      text-decoration: none;
      box-shadow: none;
      color: ${props => props.theme.links};
      :hover {
        color: ${props => props.theme.hoveredLinks};
      }
    }
    p > a {
    text-decoration: underline;
    font-weight: bold;
    }
    blockquote{
      color: ${props => props.theme.textColor};
      border-left-color: ${props => props.theme.textColor};
    }
  }
`
