import styled, { css } from "styled-components"
import { PoweredBy } from "react-instantsearch-dom"
import { Search } from "styled-icons/fa-solid/Search"
import { themeMedia } from "../../utils/theme"

export const SearchIcon = styled(Search)`
  color: ${({ theme }) => theme.textColor};
  width: 1em;
  pointer-events: none;
`
export const Input = styled.input`
  outline: none;
  border: none;
  font-size: 1em;
  align-self: center;
  background: transparent;
  transition: all 0.5s ease 0s;
  border-radius: 5px;
  ${props => (props.collapse ? collapse : expand)};

  /* & :focus {
    background: white;
    color: darkblue;
    cursor: text;
    width: 5em;
  }*/
`
export const Form = styled.form`
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
`
export const Root = styled.div`
  position: relative;
  display: grid;
  grid-gap: 1em;
  z-index: 100;
`
const focus = css`
  background: ${({ theme }) => theme.algoliaFocusBackground};
  color: ${({ theme }) => theme.textColor};
  cursor: text;
  width: 10em;
  + ${SearchIcon} {
    margin: 0.3em;
  }
`
const collapse = css`
  width: 0;
  cursor: pointer;

  ${props => props.focus && focus};
  margin-left: ${props => (props.focus ? `-1.6em` : `-1em`)};
  padding-left: ${props => (props.focus ? `1.6em` : `1em`)};
  ::placeholder {
    color: ${({ theme }) => theme.textColor};
  }
`
const expand = css`
  //background: lightyellow;
  width: 6em;
  margin-left: -1.6em;
  padding-left: 1.6em;
  + ${SearchIcon} {
    margin: 0.3em;
  }
`
export const HitsWrapper = styled.div`
  display: ${props => (props.show ? `grid` : `none`)};
  max-height: 80vh;
  overflow: scroll;
  z-index: 110;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5em);
  width: 80vw;
  max-width: 30em;
  box-shadow: ${({ theme }) => theme.baseBoxShadow};
  padding: 0.7em 1em 0.4em;
  background: ${({ theme }) => theme.algoliaHitsWrapperBackground};
  border-radius: 5px;
  @media (max-width: ${themeMedia.maxXsmall}) {
    right: -6em;
    width: 95vw;
  }
  > * + * {
    padding-top: 1em !important;
    border-top: 2px solid ${({ theme }) => theme.textColor};
  }
  li + li {
    margin-top: 0.7em;
    padding-top: 0.7em;
    border-top: 1px solid grey;
  }
  * {
    margin-top: 0;
    padding: 0;
  }
  ul {
    list-style: none;
  }
  /*mark {
    color: white;
    background: purple;
  }*/
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3em;
    h3 {
      //color: white;
      background: ${({ theme }) => theme.algoliaHitsTitleBackground};
      padding: 0.1em 0.4em;
      border-radius: 5px;
    }
  }
  h3 {
    margin: 0 0 0.5em;
  }
  h4 {
    margin-bottom: 0.3em;
  }
`
export const PoweredByStyled = styled(PoweredBy)`
  display: flex;
  justify-content: flex-end;
  font-size: 0.8rem;
  padding-bottom: 0;
  .ais-PoweredBy-logo {
    height: 1.4rem;
    align-self: center;
  }
  & a {
    box-shadow: none;
  }
`
