import React from "react"
import Header from "./header"
import Footer from "./Footer"
import styled, { ThemeProvider } from "styled-components"
import { rhythm } from "../utils/typography"
import ScrollToTop from "./ScrollToTop"
import theme from "../utils/theme"
import { GlobalStyle } from "./styles/GlobalStyle"
import useDarkMode from "use-dark-mode"
//Styles

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
const StyledMain = styled.main`
  max-width: ${props => (props.isRootOrPagination ? rhythm(32) : rhythm(24))};
  margin: 0 auto;
  padding: ${rhythm(1 / 2)};
  flex: 1;
`
const IndexLayout = props => {
  const { children, location, title } = props
  //check if we are in root path or pagination for styling purpose
  const isRootPath = location.pathname === `${__PATH_PREFIX__}/`
  const pageNumber = location.pathname
    .split("/")
    .filter(Boolean)
    .pop()
  const isPagination = pageNumber && Boolean(pageNumber.match(/^[0-9]+$/))
  const isDarkMode = useDarkMode().value

  return (
    <ThemeProvider theme={theme(isDarkMode)}>
      <Container>
        <GlobalStyle />
        <Header location={location} title={title} />
        <StyledMain isRootOrPagination={isRootPath || isPagination}>
          {children}
        </StyledMain>
        <ScrollToTop displayButtonBelow={900} />
        <Footer />
      </Container>
    </ThemeProvider>
  )
}

export default IndexLayout
